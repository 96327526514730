<template>
  <div>
  <div v-if="active_notices.length == 0 || !notice_obj">
      <b-alert
        show
        variant="info"
      >
        No active notices.
      </b-alert>
  </div>
  <div v-else>
    <b-form-row>
      <b-col>
        <b-form-select
          v-model="notice_id_selected"
          :options="notice_options"
          @change="onChangeSelectedNotice"
        ></b-form-select>
      </b-col>
      <b-col>
        <b-button variant="danger" @click="onDeleteNotice"
          >Delete Notice</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button :disabled="!sign_ok" variant="primary" @click="onSignNotice"
          >Proceed to Sign and Submit Notice</b-button
        >
      </b-col>
    </b-form-row>

    <b-row class="mt-3">
      <b-col>
        <b>Monitoring Period:</b>
        &nbsp;{{ notice_obj.mp_name }},
        {{ notice_obj.cp_prd_begin_dt }} -
        {{ notice_obj.cp_prd_end_dt }}
      </b-col>
    </b-row>

    <div>
      <b>Sample Count:</b> &nbsp;{{ notice_samples.length }} &nbsp;
      <b>Total Collected in MP:</b> &nbsp;{{ notice_obj.mnr_sample_count }}
    </div>

    <div class="mt-3">
      <b-button
        size="sm"
        variant="success"
        href="/static/docs/LCR_Consumer_Notice_Instructions.pdf"
        target="_blank"
      >
        <b-icon icon="download" />
        Consumer Notice Instructions
      </b-button>
      <b-button
        class="ml-2"
        size="sm"
        variant="success"
        @click="showNoticeSampleResults"
      >
        <b-icon icon="download" />
        Download All Sample Results
      </b-button>
      <b-button
        class="ml-2"
        :disabled="!contact_info_ok"
        size="sm"
        variant="success"
        @click="showNoticeReport"
      >
        <b-icon icon="download" />
        Download Customer Notice
      </b-button>
    </div>

    <b-card class="mt-3" v-if="signing_notice" title="Sign and Submit to MSDH">
      <b-card-text>
        <p><b>Water System:</b> {{ pws_label }}</p>
        <p>
        The public water system indicated above herby affirms that
        “Consumer Notice Regarding Your Drinking Water” and sample
        results have been provided to consumers in accordance with
        the delivery, content, and format requirements and deadlines
        given by method(s) indicated below.
        </p>
        <ul>
          <li v-if="notice_obj.delivery_direct_date">
            <b>Hand or Direct Delivery</b> on {{ notice_obj.delivery_direct_date }}
          </li>
          <li v-if="notice_obj.delivery_mail_date">
            <b>Mail as separate notice or with bill</b> on {{ notice_obj.delivery_mail_date }}
          </li>
          <li v-if="notice_obj.delivery_alt_date">
            <b>Alternate method</b> on {{ notice_obj.delivery_alt_date }}
          </li>
        </ul>
        <b-button @click="onShowSignModal" variant="primary">I agree to the above statement.</b-button>

        <b-button class="ml-2" @click="view_signing_data=true" variant="outline-primary">View digital record.</b-button>
        <div class="mt-3" v-if="view_signing_data">
          <p><b>Signing Data:</b></p>
          <p>This is the data that will be digitally signed and archived.</p>
          <pre>
          {{ signing_data }}
          </pre>
        </div>
      </b-card-text>
    </b-card>

    <!-- Notice Information -->
    <b-form
      @submit.prevent="onSubmitNoticeInfo"
      @reset="onResetNoticeInfo"
    >
      <b-card
        class="mt-3"
        title="Contact Information"
        sub-title="Required to create customer notice"
      >
        <b-card-text>
          <b-alert
            dismissible
            v-model="alert_notice_info_saved"
            variant="success"
          >
            Contact information saved.
          </b-alert>
          <b-alert
            dismissible
            v-model="alert_notice_info_error"
            variant="danger"
          >
            There was an error saving the contact information.
          </b-alert>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Name (required)"
                label-for="contact_name"
              >
                <b-form-input
                  id="contact_name"
                  v-model="form_contact_name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Phone (required)"
                label-for="contact_phone"
              >
                <b-form-input
                  id="contact_phone"
                  type="tel"
                  v-model="form_contact_phone"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group label="Email" label-for="contact_email">
                <b-form-input
                  id="contact_email"
                  type="email"
                  v-model="form_contact_email"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Organization"
                label-for="contact_org"
              >
                <b-form-input
                  id="contact_org"
                  v-model="form_contact_org"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col>
              <b-form-group
                label="Additional Contact Info"
                label-for="contact_additional"
              >
                <b-form-input
                  id="contact_additional"
                  v-model="form_contact_additional"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-button
            :disabled="!can_save_contact_info"
            variant="primary"
            @click="onUpdateNoticeContactInfo"
            >Save Contact Information</b-button
          >
        </b-card-text>
        <b-card-text> </b-card-text>
      </b-card>

      <b-card
        class="mt-3"
        title="Delivery Information"
        sub-title="Required to sign and submit to MSDH."
      >
        <b-card-text>
          <b-alert
            dismissible
            v-model="alert_delivery_info_saved"
            variant="success"
          >
            Delivery information saved.
          </b-alert>
          <b-alert
            dismissible
            v-model="alert_delivery_info_error"
            variant="danger"
          >
            There was an error saving the delivery information.
          </b-alert>
          <b-form-row>
            <b-col>
              <!-- direct delivery date -->
              <b-form-group
                label="Direct Delivery Date"
                label-for="delivery_direct_date"
              >
                <b-form-input
                  id="delivery_direct_date"
                  type="date"
                  v-model="form_delivery_direct_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <!-- mail delivery date -->
              <b-form-group
                label="Mail Delivery Date"
                label-for="delivery_mail_date"
              >
                <b-form-input
                  id="delivery_mail_date"
                  type="date"
                  v-model="form_delivery_mail_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <!-- mail delivery date -->
              <b-form-group
                label="Alternative Delivery Date"
                label-for="delivery_alt_date"
              >
                <b-form-input
                  id="delivery_alt_date"
                  type="date"
                  v-model="form_delivery_alt_date"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-button
            :disabled="!can_save_delivery_info"
            variant="primary"
            @click="onUpdateNoticeDeliveryInfo"
            >Save Delivery Information</b-button
          >
        </b-card-text>
      </b-card>
    </b-form>

    <div class="mt-3">
      {{ notice_samples.length }} samples in notice
    </div>

    <b-table
      hover
      small
      :busy.sync="notice_sample_table_busy"
      ref="notice_sample_table"
      :items="notice_samples"
      :fields="notice_sample_fields"
    >
      <template #cell(options)="data">
        <b-dropdown
          size="sm"
          text="Options"
          variant="outline-primary"
        >
          <b-dropdown-item
            @click="onRemoveNoticeSample(data.item.id)"
          >
            Remove from Notice
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <template #cell(lab_asgnd_id_num)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="onShowSampleResults(data.item.id)"
        >
          {{ data.value }}
        </b-button>
      </template>
    </b-table>
  </div>

    <b-modal id="sample-modal" size="xl" :title="show_sample_title" hide-footer>
      <object
        width="100%"
        height="600px"
        :data="show_sample_pdf_url"
        type="application/pdf"
      >
        <p>
          Unable to display PDF file.
          <a :href="show_sample_pdf_url">Download</a> instead.
        </p>
      </object>
    </b-modal>

    <b-modal
      id="notice-samples-modal"
      size="xl"
      :title="show_notice_samples_report_title"
      hide-footer
    >
      <object
        width="100%"
        height="600px"
        :data="show_notice_samples_report_url"
        type="application/pdf"
      >
        <p>
          Unable to display PDF file.
          <a :href="show_notice_samples_report_url">Download</a> instead.
        </p>
      </object>
    </b-modal>

    <b-modal
      id="notice-report-modal"
      size="xl"
      :title="show_notice_report_title"
      hide-footer
    >
      <object
        width="100%"
        height="600px"
        :data="show_notice_report_url"
        type="application/pdf"
      >
        <p>
          Unable to display PDF file.
          <a :href="show_notice_report_url">Download</a> instead.
        </p>
      </object>
    </b-modal>

    <SignAndSend
      :submit_title="`Lead Notice for ${pws_label}`"
      :submit_url="signing_url"
      :submit_data="signing_data"
      @signing-success="onNoticedSigned"
    />

  </div>

</template>

<script>
import SignAndSend from "@/components/SignAndSend.vue"

export default {
  name: "ActiveNotices",
  components: {
    SignAndSend,
  },
  props: {
    active_notices: Array,
  },
  watch: {
    active_notices: {
      handler: function (newVal, oldVal) {
        console.log("Active Notices Updated");
        console.log(oldVal, newVal);
        if (newVal.length != oldVal.length) {
          this.getActiveNoticeTab();
        }
      },
      deep: false,
    },
  },
  data() {
    return {
      notice_id_selected: null,
      show_sample: null,
      show_sample_id: null,
      show_sample_title: "Sample Results",
      show_sample_pdf_url: null,
      notice_sample_fields: [
        "options",
        { key: "date_collected", label: "Collection Date", sortable: true },
        { key: "lab_id_number", label: "Lab ID", sortable: true },
        { key: "lab_asgnd_id_num", label: "Sample ID", sortable: true },
        { key: "sp_id", label: "Sample Point", sortable: true },
        { key: "note_1", label: "Sample Location" },
      ],
      // ********* Notices ********* //
      notice_options: [],
      notice_samples: [],
      notice_samples_selected: [],
      notice_sample_table_busy: false,
      alert_notice_info_saved: false,
      alert_notice_info_error: false,
      // notice samples report
      show_notice_samples_report_url: null,
      show_notice_samples_report_title: "Notice Sample Results",
      // notice report
      show_notice_report_url: null,
      show_notice_report_title: "Sample Notice",
      // form data - contacts
      form_contact_name: "",
      form_contact_phone: "",
      form_contact_email: "",
      form_contact_org: "",
      form_contact_additional: "",
      // form data - delivery
      form_delivery_direct_date: "",
      form_delivery_mail_date: "",
      form_delivery_alt_date: "",
      alert_delivery_info_saved: false,
      alert_delivery_info_error: false,
      // signing
      signing_notice: false,
      signing_data: {},
      view_signing_data: false,
      signing_url: null,

    };
  },
  mounted() {
    this.getActiveNoticeTab();
  },
  methods: {
    async getNoticeSamples() {
      if (this.notice_id_selected == null) {
        return;
      }
      if (this.active_notices.length == 0) {
        return;
      }
      console.log("Get Notice Samples for Notice: ", this.notice_id_selected);
      const url =
        process.env.VUE_APP_API_URI +
        `/lc-notice/notices/${this.notice_id_selected}/samples`;
      this.notice_sample_table_busy = true;
      try {
        const res = await this.axios.get(url);
        console.log(res);
        if (res.data && res.data.samples) {
          this.notice_samples = res.data.samples;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.notice_sample_table_busy = false;
      }
    },
    showNoticeSampleResults() {
      this.show_notice_samples_report_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected +
        "/samples/report";
      console.log(this.show_notice_samples_report_url);
      // construct the title
      this.show_notice_samples_report_title = `Sample Results - ${this.pws_label}`;
      this.$bvModal.show("notice-samples-modal");
    },
    showNoticeReport() {
      this.show_notice_report_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected +
        "/report";
      // construct the title
      this.show_notice_report_title = `Sample Notice - ${this.pws_label}`;
      this.$bvModal.show("notice-report-modal");
    },
    async onRemoveNoticeSample(sample_id) {
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected +
        "/samples/" +
        sample_id;
      console.log("Delete Notice Sample: ", url);
      try {
        const res = await this.axios.delete(url);
        console.log(res);
        if (res.data && res.data.msg == "deleted") {
          console.log("Removing");
          // remove from notice_samples
          this.notice_samples = this.notice_samples.filter(
            (s) => s.id != sample_id
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
    resetNoticeTabAlerts() {
      this.alert_notice_info_saved = false;
      this.alert_notice_info_error = false;
      this.alert_delivery_info_saved = false;
      this.alert_delivery_info_error = false;
    },
    processActiveNotices() {
      console.log("Process Active Notices");
      this.notice_options = [];
      this.notice_samples = [];
      this.notice_id_selected = null;
      this.resetNoticeTabAlerts();
      // length
      for (const notice of this.active_notices) {
        if (this.notice_id_selected == null) {
          this.notice_id_selected = notice.id;
        }
        const dtstr = new Date(notice.dt_init).toLocaleString();
        this.notice_options.push({
          value: notice.id,
          text: `Notice ${notice.id} - Created: ${dtstr}`,
        });
      }
    },
    async getActiveNoticeTab() {
      console.log("Get Active Notice Tab");
      //await this.getActiveNotices();
      this.processActiveNotices();
      this.setNoticeFormFields();
      await this.getNoticeSamples();
    },
    async onChangeSelectedNotice() {
      console.log("Selected Notice: ", this.notice_id_selected);
      this.setNoticeFormFields();
      await this.getNoticeSamples();
    },
    setNoticeFormFields() {
      if (!this.notice_id_selected) {
        return;
      }
      this.form_contact_name = this.notice_obj.contact_name;
      this.form_contact_phone = this.notice_obj.contact_phone;
      this.form_contact_email = this.notice_obj.contact_email;
      this.form_contact_org = this.notice_obj.contact_org;
      this.form_contact_additional = this.notice_obj.contact_additional;
      // delivery dates
      this.form_delivery_direct_date = this.notice_obj.delivery_direct_date;
      this.form_delivery_mail_date = this.notice_obj.delivery_mail_date;
      this.form_delivery_alt_date = this.notice_obj.delivery_alt_date;
    },
    async onUpdateNoticeContactInfo() {
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected +
        "/contact_info";
      const json_data = {
        name: this.form_contact_name,
        phone: this.form_contact_phone,
        email: this.form_contact_email,
        org: this.form_contact_org,
        additional: this.form_contact_additional,
      };
      this.alert_notice_info_saved = false;
      this.alert_notice_info_error = false;
      try {
        const res = await this.axios.post(url, json_data);
        console.log(res);
        if (res.data && res.data.msg == "updated") {
          this.alert_notice_info_saved = true;
          // update notice_obj
          this.notice_obj.contact_name = this.form_contact_name;
          this.notice_obj.contact_phone = this.form_contact_phone;
          this.notice_obj.contact_email = this.form_contact_email;
          this.notice_obj.contact_org = this.form_contact_org;
          this.notice_obj.contact_additional = this.form_contact_additional;
        }
      } catch (error) {
        console.error(error);
        this.alert_notice_info_error = true;
      }
    },
    async onUpdateNoticeDeliveryInfo() {
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected +
        "/delivery_info";
      const json_data = {
        direct_date: this.form_delivery_direct_date,
        mail_date: this.form_delivery_mail_date,
        alt_date: this.form_delivery_alt_date,
      };
      this.alert_delivery_info_saved = false;
      this.alert_delivery_info_error = false;
      try {
        const res = await this.axios.post(url, json_data);
        console.log(res);
        if (res.data && res.data.msg == "updated") {
          this.alert_delivery_info_saved = true;
          // update notice_obj
          this.notice_obj.delivery_direct_date = this.form_delivery_direct_date;
          this.notice_obj.delivery_mail_date = this.form_delivery_mail_date;
          this.notice_obj.delivery_alt_date = this.form_delivery_alt_date;
        }
      } catch (error) {
        console.error(error);
        this.alert_delivery_info_error = true;
      }
    },
    async onDeleteNotice() {
      console.log("Delete Notice: ", this.notice_id_selected);
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected;
      try {
        const res = await this.axios.delete(url);
        console.log(res);
        if (res.data && res.data.msg == "deleted") {
          // reload notices and notice_samples
          this.$emit("update-active-notices");
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateSigningData() {
      console.log("Update Signing Data");
      const sigdata = {}
      sigdata.pws_id = this.pws_id_signed_in
      sigdata.mp_name = this.notice_obj.mp_name
      sigdata.notice_id = this.notice_id_selected
      sigdata.contact_name = this.form_contact_name
      sigdata.contact_phone = this.form_contact_phone
      sigdata.contact_email = this.form_contact_email
      sigdata.contact_org = this.form_contact_org
      sigdata.contact_additional = this.form_contact_additional
      sigdata.delivery_direct_date = this.form_delivery_direct_date
      sigdata.delivery_mail_date = this.form_delivery_mail_date
      sigdata.delivery_alt_date = this.form_delivery_alt_date
      // add list of sample ids (lab_id, lab_asgnd_id_num)
      sigdata.samples = this.notice_samples.map(s => {
        return {
          lab_id: s.lab_id_number,
          lab_sample_id: s.lab_asgnd_id_num
        }
      })
      this.signing_data = sigdata
    },
    onSignNotice() {
      console.log("Sign and Submit Notice");
      this.signing_notice = true;
      this.updateSigningData()
      this.signing_url = "lc-notice/notices/" + this.notice_id_selected + "/sign";
    },
    onNoticedSigned(data) {
      console.log("Notice Signed: ", data);
      this.signing_notice = false;
      this.$emit("update-active-notices");
    },
    onShowSampleResults(sample_id) {
      this.show_sample_pdf_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/samples/" +
        sample_id +
        "/report";
      this.show_sample_title = "Lead and Copper Sample Results - " + this.pws_id_signed_in;
      this.$bvModal.show("sample-modal");
    },

    onShowSignModal() {
      this.$bvModal.show('sign-send')
    },
    onSubmitNoticeInfo() {
      console.log("Submit Notice Info");
    },
    onResetNoticeInfo() {
      console.log("Reset Notice Info");
    },
  },
  computed: {
    contact_info_changed() {
      return this.form_contact_name != this.notice_obj.contact_name ||
        this.form_contact_phone != this.notice_obj.contact_phone ||
        this.form_contact_email != this.notice_obj.contact_email ||
        this.form_contact_org != this.notice_obj.contact_org ||
        this.form_contact_additional != this.notice_obj.contact_additional;
    },
    contact_info_ok() {
      return (
        this.notice_obj.contact_name &&
        this.notice_obj.contact_phone &&
        !this.contact_info_changed
      );
    },
    delivery_info_changed() {
      return this.form_delivery_direct_date != this.notice_obj.delivery_direct_date ||
        this.form_delivery_mail_date != this.notice_obj.delivery_mail_date ||
        this.form_delivery_alt_date != this.notice_obj.delivery_alt_date;
    },
    delivery_info_ok() {
      return (this.notice_obj.delivery_direct_date || this.notice_obj.delivery_mail_date || this.notice_obj.delivery_alt_date) && !this.delivery_info_changed;
    },
    sign_ok() {
      return this.notice_samples.length > 0 && this.contact_info_ok && this.delivery_info_ok && !this.signing_notice;
    },
    can_save_contact_info() {
      return (
        this.contact_info_changed &&
        this.form_contact_name &&
        this.form_contact_phone
      );
    },
    can_save_delivery_info() {
      return this.delivery_info_changed && (this.form_delivery_direct_date || this.form_delivery_mail_date || this.form_delivery_alt_date);
    },
    notice_obj() {
      return this.active_notices.find((n) => n.id == this.notice_id_selected);
    },
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`;
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id;
    },
    signed_in() {
      return this.$store.state.signed_in;
    },

  }
}

</script>
