<template>
  <div class="ccr">
    <h3>Consumer Confidence Reports (CCR)</h3>
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Consumer Confidence Report Data">
          <b-card-text>
            <CCRReportData />
          </b-card-text>
        </b-tab>
        <b-tab title="CCR Uploads">
          <b-card-text>
            <CCRUploads />
          </b-card-text>
        </b-tab>
      <b-tab title="CCR Status">
        <b-card-text>
          <CCRStatus />
        </b-card-text>
      </b-tab>
      </b-tabs>
    </b-card>
    <p class="mt-5">If you have CCR related questions, call 601-576-7518 for the MSDH Bureau of Public Water Supply main office or e-mail <a href="mailto:Joan.Cockrell@msdh.ms.gov?subject=CCR">Joan Cockrell</a> or <a href="mailto:Charles.Shultis@msdh.ms.gov?subject=CCR">Charles Shultis</a>.  For technical questions or issues with this website, e-mail <a href="mailto:Randall.Smith@msdh.ms.gov?subject=CCR">Randall Smith</a>.
    </p>
  </div>
</template>
<script>
import CCRReportData from "@/components/CCRReportData.vue"
import CCRUploads from "@/components/CCRUploads.vue"
import CCRStatus from "@/components/CCRStatus.vue"

export default {
  name: "CCR",
  components: {
    CCRReportData,
    CCRUploads,
    CCRStatus
  },
  data() {
    return {
    }
  },
  methods: {
    // pass
  },
  mounted() {
    // pass
  },
  computed: {
    // pass
  }
}
</script>
