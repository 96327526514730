<template>
  <div>
    <h3 class="mt-2 mb-4">Sample Results</h3>
    <p>
      These sample results are from the
      <strong>MS Public Health Laboratory</strong>. Results processed by other
      laboratories are not (yet) available here. Results are made available as
      they are processed beginning in the later part of 2022.
    </p>
    <p>
      You can choose to be notified about new sample results in the
      <b-link to="settings">Settings</b-link>.
    </p>
    <b-form-group label="Sample Year" description="Year sample was taken.">
      <b-form-select
        @change="fetchSampleResults"
        v-model="sample_year"
        :options="year_options"
      ></b-form-select>
    </b-form-group>

    <p v-if="!Object.keys(sample_results).length">
      No Sample Results - Try selecting another year.
    </p>

    <div v-if="Object.keys(sample_results).length">
      <p>
        Results are organized by sample type. Click on a tab to see the results
        for that sample type.
      </p>
      <b-card no-body>
        <b-tabs card>
          <b-tab
            v-for="sample_type in Object.keys(sample_results)"
            v-bind:key="sample_type"
            :title="
              `${sample_type} (${sample_results[sample_type].files.length})`
            "
          >
            <h6 v-if="sample_type">
              {{ sample_type }} - {{ sample_results[sample_type].name }}
            </h6>
            <p>
              Files are listed by collection date, most recent first. Click on a
              button to download the sample result file.
            </p>

            <!--
					<b-card-text class="mt-4">
            <ul v-for="file in sample_results[sample_type].files" v-bind:key="file.name">
              <a :href="file.url" target="_blank">{{file.name}}</a> &nbsp; {{file.date}}
            </ul>
          </b-card-text>
          -->

            <b-table
              striped
              hover
              outlined
              responsive
              :items="sample_results[sample_type].files"
              :fields="report_fields"
            >
              <template #cell(filename)="data">
                <b-button
                  variant="primary"
                  :href="data.item.url"
                  target="_blank"
                  >{{ data.value }}</b-button
                >
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
const this_year = new Date().getFullYear()
console.log(this_year)

//function convertKeyedData(data) {
//  const new_data = []
//  for (let i = 0; i < data.data.length; i++) {
//    const dset = data.data[i]
//    const new_dset = {}
//    for (let k = 0; k < data.keys.length; k++) {
//      const key = data.keys[k]
//      new_dset[key] = dset[k]
//    }
//    new_data.push(new_dset)
//  }
//  return new_data
//}
function convertDateString(dtstr) {
  let ret = ""
  if (dtstr) {
    ret = new Date(dtstr).toLocaleDateString("sv")
  }
  return ret
}

function convertDateTimeString(dtstr) {
  let ret = ''
  if (dtstr) {
    ret = new Date(dtstr).toLocaleString("en-US", {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})
  }
  return ret
}

function formatDTSForSort(dtstr) {
  let ret = ''
  if (dtstr) {
    ret = new Date(dtstr).toISOString() 
  }
  return ret
}

export default {
  name: "SampleResults",
  data() {
    return {
      sample_year: this_year.toString(),
      year_options: [],
      pws_id: "",
      sample_type_defs: {},
      sample_results: {},
      report_fields: [
        { key: "filename", label: "Report", sortable: false },
        { key: "sample_id", label: "Sample ID", sortable: true },
        {
          key: "dt_collected",
          label: "DT Collected",
          headerTitle: "Date and time collected.",
          sortable: true,
          formatter: convertDateTimeString,
          sortByFormatted: formatDTSForSort,
        },
        {
          key: "dt_init",
          label: "DT Available",
          headerTitle: "Date and time made available for download.",
          sortable: true,
          formatter: convertDateTimeString,
          sortByFormatted: formatDTSForSort,
        }
      ]
    }
  },
  mounted() {
    this.pws_id = this.$store.getters.pws_id
    const year_start = 2022
    for (let i = year_start; i <= this_year; i++) {
      this.year_options.push(i.toString())
    }
    this.year_options.reverse()
    this.doInitialFetch()
  },
  methods: {
    async doInitialFetch() {
      // assures order
      await this.fetchTypeDefs()
      await this.fetchSampleResults()
    },
    async fetchTypeDefs() {
      const url = process.env.VUE_APP_API_URI + "/sample-type-defs"
      const result = await this.axios.get(url, {
        params: { pws_id: this.pws_id }
      })
      if (result.data) {
        this.sample_type_defs = result.data
      }
      console.debug(this.sample_type_defs)
    },
    async fetchSampleResults() {
      const url = process.env.VUE_APP_API_URI + "/sample-results"
      const params = { pws_id: this.pws_id, year: this.sample_year }
      const result = await this.axios.get(url, { params: params })
      const sample_results = {}
      if (result.data) {
        console.debug("got data", result.data)
        for (const [key, value] of Object.entries(result.data.data)) {
          let newkey = key
          let sample_type_name = ""
          if (Object.keys(this.sample_type_defs).includes(key)) {
            newkey = this.sample_type_defs[key][0]
            sample_type_name = this.sample_type_defs[key][1]
          }
          const files = []
          for (const lset of value) {
            const dset = {}
            result.data.keys.forEach((key, i) => (dset[key] = lset[i]))
            //const datepart = dset.filename.split('-')[1]
            //const year = '20' + datepart.substring(0, 2)
            //const month = datepart.substring(2, 4)
            //const day = datepart.substring(4, 6)
            dset.name = dset.filename
            dset.url =
              "/sample_results/" +
              this.pws_id +
              "/" +
              this.sample_year +
              "/" +
              key +
              "/" +
              dset.filename
            dset.date = convertDateString(dset.dt_collected)
            files.push(dset)
          }
          sample_results[newkey] = {
            name: sample_type_name,
            files: files
          }
        }
      }
      this.sample_results = sample_results
      console.debug(this.sample_results)
      console.debug(Object.keys(this.sample_results))
    }
  }
}
</script>
<style>
.table td { vertical-align: middle; }
</style>
