<template>
  <div>
    <b-table
      small
      responsive
      striped
      hover
      :items="sample_points"
      :fields="table_fields"
    >
    </b-table>
  </div>
</template>

<script>
export default {
  name: "SampleSites",
  props: {
    pws_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sample_points: [],
      table_fields: [
        { key: "sp_id", label: "Site ID" },
        { key: "note_1", label: "Location" },
      ],
    }
  },
  mounted() {
    this.getSamplePoints();
  },
  methods: {
    async getSamplePoints() {
      const url =
        process.env.VUE_APP_API_URI + "/sample-points/lc?pws_id=" + this.pws_id;
      try {
        const res = await this.axios.get(url);
        if (res.data && res.data.sample_points) {
          this.sample_points = res.data.sample_points;
        }
      } catch (error) {
        console.error(error);
      }
    }
  },
  computed: {
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`;
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id;
    },
  },
};
</script>
