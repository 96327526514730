<template>
  <div class="contianer">
    <h4>Lead and Copper Sampling and Reporting</h4>
    <h6>{{ pws_label }}</h6>
    <b-alert show dismissible variant="info">
        See a demonstration under the Help/Demo tab.
    </b-alert>
    <b-card no-body>
      <b-tabs card>
        <b-tab lazy title="New Samples">
          <NewLeadSamples
            @notice-created="onNoticeCreated"
            @show-sample-results="onShowSampleReport"
            :active_notices="active_notices"
          />
        </b-tab>
        <b-tab lazy :title="`Active Notices (${active_notices.length})`">
          <ActiveNotices
            :active_notices="active_notices"
            @update-active-notices="getActiveNotices"
          />
        </b-tab>
        <b-tab lazy title="Completed Notices">
          <b-card-text>
            <SignedNotices
              :pws_id="pws_id_signed_in"
            />
          </b-card-text>
        </b-tab>
        <b-tab lazy title="Sample Sites">
          <b-card-text>
            <SampleSites
              :pws_id="pws_id_signed_in"
            />
          </b-card-text>
        </b-tab>
        <b-tab title="Documents">
          <PdfModal
            modal_id="operator_instructions_modal"
            pdf_url="/static/docs/Operator_Instructions_5.30.24.pdf"
            pdf_title="Water Operator – Instructions for Lead and Copper (Tap Sampling)"
          />
          <PdfModal
            modal_id="coc_example_modal"
            pdf_url="/static/docs/Chain_Of_Custody_Example.pdf"
            pdf_title="Example: Chain of Custody (COC)"
          />
          <PdfModal
            modal_id="homeowner_occupant_modal"
            pdf_url="/static/docs/Homeowner_Occupant_Worksheet_6.6.24.pdf"
            pdf_title="Homeowner/Occupant Worksheet"
          />
          <p>
            These documents are a part of the lead and copper sample kit. Download them if you need additional copies.
          </p>
          <ul>
            <li>
              <b-link @click="$bvModal.show('operator_instructions_modal')">
                Water Operator – Instructions for Lead and Copper (Tap Sampling)
              </b-link>
            </li>
            <li>
              <b-link @click="$bvModal.show('coc_example_modal')">
              Example: Chain of Custody (COC)
              </b-link>
            </li>
            <li>
              <b-link @click="$bvModal.show('homeowner_occupant_modal')">
              Homeowner/Occupant Worksheet
              </b-link>
            </li>
          </ul>
        </b-tab>
        <b-tab title="Help/Demo">
          <h6>Lead Reporting Demonstration</h6>
          <a target=_blank href="https://pws.mswater.us/static/lead_reporting_demo.mp4">Lead and Copper Reporting Usage Demo</a>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import NewLeadSamples from "@/components/lead_reports/NewLeadSamples.vue";
import ActiveNotices from "@/components/lead_reports/ActiveNotices.vue";
import SignedNotices from "@/components/lead_reports/SignedNotices.vue";
import SampleSites from "@/components/lead_reports/SampleSites.vue";
import PdfModal from "@/components/PdfModal.vue";

export default {
  name: "LeadResults",
  components: {
    NewLeadSamples,
    ActiveNotices,
    SignedNotices,
    SampleSites,
    PdfModal,
  },
  data() {
    return {
      // ********* Notices ********* //
      active_notices: [],
    };
  },
  methods: {
    async getActiveNotices() {
      // clear existing data
      //this.active_notices = [];
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices?pws_id=" +
        this.pws_id_signed_in;
      try {
        const res = await this.axios.get(url);
        console.log(res);
        this.active_notices = res.data.notices;
        //this.active_notices.length = 0;
        //for (const notice of res.data.notices) {
        //  this.active_notices.push(notice);
        //}
      } catch (error) {
        console.error(error);
      }
    },
    //updateActiveNotices(notices) {
    //  this.active_notices = notices;
    //},
    showSampleResults() {
      this.show_sample_pdf_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/samples/" +
        this.show_sample.id +
        "/report";
      this.show_sample_title = "Lead and Copper Sample Results - " + this.pws_id_signed_in;
      // construct the title
      //const lab_id = this.show_sample.lab_id_number;
      //const lab_sample_id = this.show_sample.lab_asgnd_id_num;
      //this.show_sample_title = `Sample Results - Lab ID: ${lab_id} - Sample ID: ${lab_sample_id}`;
      this.$bvModal.show("sample-modal");
    },
    showNoticeSampleResults() {
      this.show_notice_samples_report_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected +
        "/samples/report";
      console.log(this.show_notice_samples_report_url);
      // construct the title
      this.show_notice_samples_report_title = `Sample Results - ${this.pws_label}`;
      this.$bvModal.show("notice-samples-modal");
    },
    showNoticeReport() {
      this.show_notice_report_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        this.notice_id_selected +
        "/report";
      // construct the title
      this.show_notice_report_title = `Sample Notice - ${this.pws_label}`;
      this.$bvModal.show("notice-report-modal");
    },
    onShowSampleNewResults(sample_id) {
      this.show_sample = this.new_samples_mp[this.mp_selected].find(
        (s) => s.id == sample_id
      );
      this.showSampleResults();
    },
    onShowSampleResults(sample_id) {
      this.show_sample = this.notice_samples.find((s) => s.id == sample_id);
      this.showSampleResults();
    },
    onShowSampleReport(sample_id) {
      console.log("Show Sample Report: ", sample_id);
      this.show_sample_id = sample_id
      this.show_sample_pdf_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/samples/" + sample_id + "/report";
      this.show_sample_title = "Lead and Copper Sample Results - " + this.pws_id_signed_in;
      this.$bvModal.show("sample-modal");
    },
    onNoticeCreated() {
      this.getActiveNotices()
    }
  },
  mounted() {
    //this.getNewLCSamples();
    this.getActiveNotices();
  },
  watch: {
  },
  computed: {
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`;
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id;
    },
    signed_in() {
      return this.$store.state.signed_in;
    },
  },
};
</script>
<style scoped>
.form-group.required > label:after {
  content: " *";
  color: red;
}
</style>
