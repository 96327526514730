<template>
  <div class="ccr">
    <div v-if="signed_in && pws_id_signed_in">
      <h4>CCR Status</h4>
      <p>
        <b>{{ current_pws_label }}</b>
      </p>
      <b-alert :show="ccr_review_status == 'Approved'" variant="success">
        COMPLETE: All documents have been reviewed and approved.
      </b-alert>
      <b-alert :show="ccr_review_status != 'Approved'" variant="warning">
        <b>Status:</b> {{ ccr_review_status }}
      </b-alert>
     <div v-for="(doc, doc_name) in docs" :key="doc_name">
            <b-row>
              <b-col cols="4">
                <b>{{ doc.label }}</b>
              </b-col>
              <b-col cols="4">
                <b-form-checkbox v-model="doc.recieved" disabled>Received</b-form-checkbox>
              </b-col>
              <b-col cols="4">
                <b-form-checkbox v-model="doc.approved" disabled>Approved</b-form-checkbox>
              </b-col>
            </b-row>
    </div>

      <h5 class="mt-4">CCR Files Uploaded</h5>
      <!-- large status with colored background -->
      <b-alert :show="unreviewed_docs" variant="info">
        There are unreviewed documents.
      </b-alert>
      <div v-if="status_data.length > 0">
        <b-table class="mt-3" striped :items="status_data" :fields="table_fields">
          <template #cell(id)="data">
            <!-- link to download document -->
            <b-button @click="showPDF(data.value)" size="sm" variant="primary">View</b-button>
          </template>
          <template #cell(has_ccr)="data">
            <span style="font-size: 1.5em; color: green;" v-if="data.value">&#x2713;</span>
            <span v-else>&nbsp;</span>
          </template>
          <template #cell(has_cert)="data">
            <span style="font-size: 1.5em; color: green;" v-if="data.value">&#x2713;</span>
            <span v-else>&nbsp;</span>
          </template>
          <template #cell(has_pod)="data">
            <span style="font-size: 1.5em; color: green;" v-if="data.value">&#x2713;</span>
            <span v-else>&nbsp;</span>
          </template>
          <template #cell(status)="data">
            {{ status_codes[data.value] }}
          </template>
          <template #cell(ts_init)="data">
            <!-- to local time -->
            {{ new Date(data.value).toLocaleString() }}
          </template>
        </b-table>
      </div>
      <div v-else>
        <b-alert show variant="warning">
          No CCR files have been uploaded.
        </b-alert>
      </div>
      <b-modal @shown="onShowPDFModal" hide-header ok-only ok-title="Done" id="modal-ccr-pdf" size="xl" title="View PDF">
        <div id="mypdf" class="w-100" style="height: 80vh;"></div>
      </b-modal>
    </div>
    <div v-else class="mt-5">
      <b-alert show variant="info">
        You must be <b-link to="signin">signed in </b-link>to <b>view CCR status</b>.
      </b-alert>
    </div>
</div>
</template>
<script>
import PDFObject from 'pdfobject'

export default {
  name: "CCRStatus",
  data() {
    return {
      status_data: [],
      status_codes: {
        'N': 'Not Reviewed',
        'A': 'Approved',
        'R': 'Rejected',
        'F': 'Follow Up',
      },
      docs: {
        'ccr': { label: 'CCR', recieved: false, approved: false},
        'cert': { label: 'Certification', recieved: false, approved: false},
        'pod': { label: 'Proof of Delivery', recieved: false, approved: false},
      },
      unreviewed_docs: false,
      ccr_review_status: 'Undetermined',
      table_fields: [
        { key: 'id', label: 'Document' },
        { key: 'status', label: 'Status' },
        { key: 'ts_init', label: 'Uploaded' },
      ],
      pdf_href: '',
    }
  },
  methods: {
    onShowPDFModal(e) {
      console.log(e)
      PDFObject.embed(this.pdf_href, '#mypdf')
    },
    showPDF(file_id) {
      //console.log(evt)
      this.pdf_href = process.env.VUE_APP_API_URI + '/ccr-file/' + file_id 
      console.log(this.pdf_href)
      this.$bvModal.show('modal-ccr-pdf')
    },
    fetchCCRStatus() {
      const url = process.env.VUE_APP_API_URI + '/ccr-status?pws_id=' + this.pws_id_signed_in
      this.axios.get(url).then((res) => {
        console.log(res)
        if (res.data && res.data.status_data) {
          this.status_data = res.data.status_data
          this.computeCCRStats()
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    computeCCRStats() {
      for (const ccr_doc of ['ccr', 'cert', 'pod']) {
        const attname = 'has_' + ccr_doc
        for (const doc_rset of this.status_data) {
          if (doc_rset.status == 'N') {
            this.unreviewed_docs = true
          }
          if (doc_rset[attname] == true) {
            this.docs[ccr_doc].recieved = true
          }
          if (doc_rset[attname] == true && doc_rset.status == 'A') {
            this.docs[ccr_doc].approved = true
          }
        }
      }
      let status = 'Incomplete'
      if (this.docs.ccr.approved && this.docs.cert.approved && this.docs.pod.approved) {
        status = 'Approved'
      } else if (this.docs.ccr.recieved && this.docs.cert.recieved && this.docs.pod.recieved) {
        status = 'Under Review'
      } else if (this.unreviewed_docs) {
        status = 'Under Review'
      }
      this.ccr_review_status = status
    }
  },
  mounted() {
    if (this.signed_in && this.pws_id_signed_in) {
      this.fetchCCRStatus()
    }
  },
  computed: {
    pws_id_signed_in() {
      return this.$store.state.pws_id
    },
    current_pws() {
      return this.$store.state.pws
    },
    current_pws_label() {
      return `${this.current_pws.pws_name} (${this.current_pws.pws_id})`
    },
    signed_in() {
      return this.$store.state.signed_in
    },
  }
}
</script>
