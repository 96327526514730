<template>
  <div>
    <b-alert
      dismissible
      v-model="alert_notice_created"
      variant="success"
      class="mt-2"
    >
      A new notice has been created for the samples you selected. Check
      the Active Notices tab to configure and download the notice.
    </b-alert>
    <b-alert
      dismissible
      v-model="alert_notice_error"
      variant="danger"
      class="mt-2"
    >
      There was an error creating the notice. Please try again.
    </b-alert>

    <div v-if="Object.keys(new_samples_mp).length == 0">
      <b-alert
        show
        variant="info"
      >
        No new samples.
        <span v-if="active_notices.length > 0">
          There are active notices. Click on the Active Notices tab to
          view and manage notices.
        </span>
      </b-alert>
    </div>
    <div v-else>
      <b-form-row>
        <b-col align-self="center" cols="4">
          Sample Sets (Grouped by Monitoring Period)
        </b-col>
        <b-col>
          <b-form-select
            v-model="mp_selected"
            :options="new_sample_set_options"
          ></b-form-select>
        </b-col>
      </b-form-row>
      <div class="mt-3">
        <div class="my-2">
          <b-button variant="primary" @click="selectAllRows"
            >Select All</b-button
          >
          <b-button
            :disabled="new_samples_selected.length == 0"
            variant="outline-primary"
            class="ml-1"
            @click="clearSelected"
            >Clear Selected</b-button
          >
          <b-button
            :disabled="new_samples_selected.length == 0"
            variant="success"
            class="ml-1"
            @click="onCreateNotice"
            >Create Notice</b-button
          >
          <b-dropdown
            v-if="active_notices.length > 0"
            :disabled="
              new_samples_selected.length == 0 ||
                active_notices.length == 0
            "
            class="ml-1"
            text="Add Samples to Notice"
            variant="outline-primary"
          >
            <b-dropdown-item
              v-bind:key="notice_option.value"
              v-for="notice_option in notice_options"
              @click="onAddNoticeSamples(notice_option.value)"
            >
              {{ notice_option.text }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-table
          selectable
          :busy.sync="new_sample_table_busy"
          hover
          small
          ref="sample_table"
          :items="new_samples_mp[mp_selected]"
          :fields="new_sample_fields"
          @row-selected="onRowSelected"
        >
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true" style="color: green;"
                >&check;</span
              >
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <template #cell(lab_asgnd_id_num)="data">
            <b-button
              variant="outline-primary"
              size="sm"
              @click="onShowSampleNewResults(data.item.id)"
            >
              {{ data.value }}
            </b-button>
          </template>
        </b-table>
      </div>
    </div>

  <b-modal id="sample-modal" size="xl" :title="show_sample_title" hide-footer>
    <object
      width="100%"
      height="600px"
      :data="show_sample_pdf_url"
      type="application/pdf"
    >
      <p>
        Unable to display PDF file.
        <a :href="show_sample_pdf_url">Download</a> instead.
      </p>
    </object>
  </b-modal>

  </div>

</template>

<script>
export default {
  name: 'NewLeadSamples',
  props: {
    active_notices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mp_selected: null,
      new_samples_mp: {},
      new_samples_selected: [],
      new_sample_table_busy: false,
      notice_created: false,
      alert_notice_created: false,
      alert_notice_error: false,
      show_sample_id: null,
      show_sample_title: "Sample Results",
      show_sample_pdf_url: null,
      new_sample_fields: [
        "selected",
        { key: "date_collected", label: "Collection Date", sortable: true },
        { key: "lab_id_number", label: "Lab ID", sortable: true },
        { key: "lab_asgnd_id_num", label: "Sample ID", sortable: true },
        { key: "sp_id", label: "Sample Point", sortable: true },
        { key: "note_1", label: "Sample Location" },
      ],
    }
  },
  mounted() {
    console.log('mounted NewLeadSamples')
    console.log(this.pws_id)
    this.getNewLCSamples()
  },
  methods: {
    async getNewLCSamples() {
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/samples?pws_id=" +
        this.pws_id_signed_in;
      this.new_sample_table_busy = true;
      try {
        const res = await this.axios.get(url);
        console.log(res);
        // clear existing data
        this.new_samples_mp = {};
        if (res.data && res.data.samples) {
          for (const sample of res.data.samples) {
            const mp_name = sample.mp_name;
            if (!this.mp_selected) {
              this.mp_selected = mp_name;
            }
            if (!this.new_samples_mp[mp_name]) {
              this.new_samples_mp[mp_name] = [];
            }
            // format dates for locale
            this.new_samples_mp[mp_name].push(sample);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.new_sample_table_busy = false;
      }
    },
    async onCreateNotice() {
      console.log("Create Notice");
      // clear alerts
      const sample_ids = this.new_samples_selected.map((s) => s.id);
      const url = process.env.VUE_APP_API_URI + "/lc-notice/notices";
      const json_data = {
        pws_id: this.pws_id_signed_in,
        sample_ids: sample_ids,
      };
      try {
        const res = await this.axios.post(url, json_data);
        console.log(res);
        this.alert_notice_created = true;
        await this.getNewLCSamples();
        this.$emit("notice-created");
      } catch (error) {
        console.error(error);
        this.alert_notice_error = true;
      }
    },
    async onAddNoticeSamples(notice_id) {
      const sample_ids = this.new_samples_selected.map((s) => s.id);
      const url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/notices/" +
        notice_id +
        "/samples";
      const json_data = {
        sample_ids: sample_ids,
      };
      try {
        const res = await this.axios.post(url, json_data);
        console.log(res);
        if (res.data && res.data.msg == "added") {
          this.getNewLCSamples();
        }
      } catch (error) {
        console.error(error);
      }
    },
    onShowSampleNewResults(sample_id) {
      console.log("Show Sample Report: ", sample_id);
      this.show_sample_id = sample_id
      this.show_sample_pdf_url =
        process.env.VUE_APP_API_URI +
        "/lc-notice/samples/" + sample_id + "/report";
      this.show_sample_title = "Lead and Copper Sample Results - " + this.pws_id_signed_in;
      this.$bvModal.show("sample-modal");
      //this.$emit("show-sample-results", sample_id);
    },
    onRowSelected(items) {
      this.new_samples_selected = items;
    },
    onNoticeRowSelected(items) {
      this.notice_samples_selected = items;
    },
    selectAllRows() {
      this.$refs.sample_table.selectAllRows();
    },
    clearSelected() {
      this.$refs.sample_table.clearSelected();
    },
  },

  computed: {
    pws_label() {
      return `${this.$store.state.pws_id} -${this.$store.state.pws.pws_name}`;
    },
    pws_id_signed_in() {
      return this.$store.state.pws_id;
    },
    new_sample_set_options() {
      const options = [];
      for (const mp_name in this.new_samples_mp) {
        options.push({
          value: mp_name,
          text: `${mp_name} (${this.new_samples_mp[mp_name].length}) samples`,
        });
      }
      return options;
    },
    notice_options() {
      return this.active_notices.map((notice) => {
        const dtstr = new Date(notice.dt_init).toLocaleString();
        return {
          value: notice.id,
          text: `Notice ${notice.id} - Created: ${dtstr}`,
        };
      });
    },
  },
}
</script>
