<template>
  <div class="board-member-list">
    <h4 class="mt-4 mb-2">
      Board Members, {{ this.pws_id }} - {{ this.pws_name }}
    </h4>

    <p class="mt-2 mb-4">
      <br />
      This is a list of Board Members currently on file at the Mississippi State Department of Health, Bureau of Public Water Supply as of {{ makeDateString() }}.  It may not reflect any recent submission that has not yet been approved.
      <br />
      <div class="no-print">
      <b-button class="mr-2" variant="primary" to="board-members">
        <b-icon icon="arrow-left"></b-icon>
        Back to Board Members
      </b-button>
      <b-button variant="primary" onclick="window.print()">
        <b-icon icon="printer"></b-icon>
        Print
      </b-button>
      <br />
      <br />
    </div>

    <div v-if="board_members.length">
      <b-card
        class="mt-3"
        v-for="board_member in board_members" v-bind:key="board_member.id"
        :header="board_member.name"
        style="page-break-inside: avoid"
      >
        <b-card-text>

          <h6>Address</h6>

          <b-row class="mx-3 mt-3">
            <b-col md>
              <strong>Street:</strong>
              &nbsp; <span>{{ board_member.street }}</span>
            </b-col>
          </b-row>

          <b-row class="mx-3 mb-3">
            <b-col md>
              <strong>City:</strong>
              &nbsp; <span>{{ board_member.city }}</span>
            </b-col>
            <b-col md>
              <strong>State:</strong>
              &nbsp; <span>{{ board_member.state }}</span>
            </b-col>
            <b-col md>
              <strong>Zip:</strong>
              &nbsp; <span>{{ board_member.zipcode }}</span>
            </b-col>
          </b-row>

          <h6>Contact</h6>

          <b-row class="mx-3 my-3">
            <b-col md>
              <strong>Phone:</strong>
              &nbsp; <span>{{ board_member.phone }}</span>
            </b-col>
            <b-col md>
              <strong>Email Address:</strong>
              &nbsp; <span>{{ board_member.email }}</span>
            </b-col>
          </b-row>

          <h6>Dates</h6>

          <b-row class="mx-3 mt-3">
            <b-col md>
              <strong>Term Begin:</strong>
              &nbsp; <span>{{ board_member.term_begin_date_formatted }}</span>
            </b-col>
            <b-col md>
              <strong>Term End:</strong>
              &nbsp; <span>{{ board_member.term_end_date_formatted }}</span>
            </b-col>
          </b-row>

          <b-row class="mx-3">
            <b-col md>
              <strong>Training:</strong>
              &nbsp; <span>{{ board_member.training_date_formatted }}</span>
            </b-col>
            <b-col md>
              <strong>Advanced Traning:</strong>
              &nbsp; <span>{{ board_member.advanced_training_date_formatted }}</span>
            </b-col>
          </b-row>


        </b-card-text>
      </b-card>
    </div>

  </div>
</template>
<script>
// @ is an alias to /src
import { SessionMixin } from "../mixins"

function formatDates(board_member) {
  const fields = [
    'training_date', 'advanced_training_date', 'term_begin_date', 'term_end_date'
  ]
  for (const fname of fields) {
    const new_fname = fname + '_formatted'
    const dtstr = board_member[fname]
    let new_value = ''
    if (dtstr) {
      new_value = new Date(dtstr).toLocaleDateString()
    }
    board_member[new_fname] = new_value
  }
}

export default {
  name: "BoardMemberList",
  mixins: [SessionMixin],
  data() {
    return {
      board_members: [],
    }
  },
  async mounted() {
    console.log("mounted")
  },

  async created() {
    console.log("Fetching Board Members")
    await this.getBoardMembers()
  },

  methods: {
    async getBoardMembers() {
      const path = "api/board-members-latest"
      let response = await this.axios.get(path, {
        params: { pws_id: this.pws_id }
      })
      console.log(response)
      if (response.data) {
        this.board_members = response.data.board_members
        for (let i=0; i < this.board_members.length; i++) {
          const rset = this.board_members[i]
          formatDates(rset)
          rset.id = i
          rset.name = `${rset.name_last}, ${rset.name_first}` 
          if (rset.title) {
            rset.name += ' - ' + rset.title
          }
        }
      }
      console.debug('bm list', this.board_members)
    },
    makeDateString() {
      let today = new Date()
      const dtstr = today.toLocaleDateString("en-US")
      return dtstr
    },
  },
}
</script>

<style>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .pagebreak { page-break-before: always; }
}
</style>
