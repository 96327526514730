<template>
  <div class="board_members">
    <h3>Board Members for {{ pws_id }}, {{ pws_name }}</h3>
    <b-alert :show="board_members_required === false" variant="info">
      According to our records, this PWS is not required to submit board members. If you do add board members, please be sure to complete all required fields.
    </b-alert>
    <div v-if="!edit_mode">
      <p>Add, Edit, or Delete Board Members. After making desired changes, click on the green "Submit Board Member List to MSDH" button below, followed by "Sign and Send".
      </p>
      <ul>
        <li>
        <u>Board Member Training:</u> Each board member must attend a minimum of eight (8)
        hours of training within two (2) years of election.
      </li>
      <li>
        <u>Advanced Board Member Training:</u> Two (2) officers from each board must complete an additional four (4) hours of training every four (4) years. 
      </li>
      </ul>
      <b-button variant="primary" to="board-member-list">
        <b-icon icon="printer"></b-icon>
        Printable List
      </b-button>
      <b-alert class="mt-3" :show="has_issues" variant="warning">
        Please note that one or more records are missing fields as indicated by the yellow buttons below.
      </b-alert>
      <b-alert class="mt-3" :show="missing_advanced_training" variant="warning">
        Please note that at least two board members need advanced training dates.
      </b-alert>
      <b-table
        class="mt-3"
        striped
        bordered
        responsive
        key="index_id"
        :fields="table_fields"
        :items="board_members"
      >
        <template #cell(edit)="data">
          <b-button @click="onEdit(data.item)" :variant="data.item.record_issues ? 'warning' : 'primary'">
            Edit
          </b-button>
        </template>
        <template #cell(name)="data">
          {{ data.item.name_last }},
          {{ data.item.name_first }}
        </template>
      </b-table>
      <b-row>
      <b-col sm="auto">
      <b-button class="my-1" variant="primary" @click="onNew">
        Add New Board Member
      </b-button>
      </b-col>
      <b-col sm>
      <b-button class="my-1" @click="showSignSend" variant="success">
        Submit Board Member List to MSDH
      </b-button>
      </b-col>
      </b-row>
      <SignAndSend
        :submit_title="`Board Members for ${pws_id}, ${pws_name}`"
        submit_url="save-board-members"
        :submit_data="submit_data"
      />
    </div>

    <validation-observer slim ref="obs1" v-slot="{ invalid, handleSubmit }">
      <div v-if="edit_mode">
        <b-card title="Board Member Information">
          <div>
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <b-form-row align-h="center">
                <b-col sm>
                  <validation-provider
                    name="First Name"
                    v-slot="validationContext"
                    rules="max:20|required"
                    key="firstname"
                  >
                    <b-form-group label="First Name">
                      <b-form-input
                        type="text"
                        v-model="bm_edit.name_first"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm>
                  <validation-provider
                    name="Last Name"
                    v-slot="validationContext"
                    rules="max:20|required"
                  >
                    <b-form-group label="Last Name">
                      <b-form-input
                        type="text"
                        v-model="bm_edit.name_last"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm>
                  <validation-provider
                    name="Title"
                    v-slot="validationContext"
                    rules="max:20"
                  >
                    <b-form-group label="Title">
                      <b-form-input
                        type="text"
                        v-model="bm_edit.title"
                        placeholder="Title"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-alert :show="bm_edit.missing_dates" variant="warning">
                Please fill out the term dates and training date.
              </b-alert>
              <b-form-row>
                <b-col sm>
                  <validation-provider
                    name="Term Begin Date"
                    v-slot="validationContext"
                    rules="regex:^\d{4}-\d{2}-\d{2}$"
                  >
                    <b-form-group label="Term Begin Date">
                      <b-form-input
                        v-model="bm_edit.term_begin_date"
                        type="date"
                        placeholder="YYYY-MM-DD"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm>
                  <validation-provider
                    name="Term End Date"
                    v-slot="validationContext"
                    rules="regex:^\d{4}-\d{2}-\d{2}$"
                  >
                    <b-form-group label="Term End Date">
                      <b-form-input
                        v-model="bm_edit.term_end_date"
                        type="date"
                        placeholder="YYYY-MM-DD"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm>
                  <validation-provider
                    name="Training Date"
                    v-slot="validationContext"
                    rules="regex:^\d{4}-\d{2}-\d{2}$"
                  >
                    <b-form-group
                      label="Training Date"
                      description="Date attended board member training"
                    >
                      <b-form-input
                        v-model="bm_edit.training_date"
                        type="date"
                        placeholder="YYYY-MM-DD"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm>
                  <validation-provider
                    name="Advanced Training Date"
                    v-slot="validationContext"
                    rules="regex:^\d{4}-\d{2}-\d{2}$"
                  >
                    <b-form-group
                      label="Advanced Training Date"
                      description="Date attended advanced board member training"
                    >
                      <b-form-input
                        v-model="bm_edit.advanced_training_date"
                        type="date"
                        placeholder="YYYY-MM-DD"
                        :state="getValidationState(validationContext)"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-alert :show="bm_edit.missing_phone" variant="warning">
                Please fill out the phone number.
              </b-alert>
              <b-form-row>
                <b-col sm>
                  <validation-provider
                    name="Phone Number"
                    v-slot="validationContext"
                    :rules="{ regex: /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/ }"
                  >
                    <b-form-group label="Phone Number">
                      <b-input
                        type="tel"
                        :state="getValidationState(validationContext)"
                        v-model="bm_edit.phone"
                        @blur="formatPhoneNumber"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm>
                  <validation-provider
                    name="Email Address"
                    v-slot="validationContext"
                    rules="email|max:40"
                  >
                    <b-form-group id="fs-8" label="E-Mail Address">
                      <b-form-input
                        :state="getValidationState(validationContext)"
                        type="email"
                        v-model="bm_edit.email"
                        trim
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-alert :show="bm_edit.address_complete === false" variant="warning">
                Please fill out the address fields below.
              </b-alert>
              <validation-provider
                name="Street Address"
                v-slot="validationContext"
                rules="max:40"
              >
                <b-form-group label="Street Address">
                  <b-form-input
                    type="text"
                    v-model="bm_edit.street"
                    placeholder="Street Address"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                </b-form-group>
              </validation-provider>

              <b-form-row>
                <b-col sm>
                  <validation-provider
                    name="City"
                    v-slot="validationContext"
                    rules="max:40"
                  >
                    <b-form-group label="City">
                      <b-form-input
                        type="text"
                        v-model="bm_edit.city"
                        placeholder="City"
                        :state="getValidationState(validationContext)"
                        trim
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm>
                  <b-form-group id="fs-12" label="State">
                    <b-form-select
                      v-model="bm_edit.state"
                      :options="state_options"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col sm>
                  <validation-provider
                    name="Zip Code"
                    v-slot="validationContext"
                    rules="zipcode"
                  >
                    <b-form-group
                      description="ZIP+4 code (e.g.,12345-6789)"
                      label="Zip Code"
                    >
                      <b-form-input
                        v-model="bm_edit.zip"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-form-row>

              <b-alert :show="bm_edit.record_issues" variant="danger">
                Please note that this record is missing fields as indicated by the yellow alerts above.
              </b-alert>
              <b-row>
                <b-col class="text-left">
                  <b-button v-b-modal.modal-delete variant="danger"
                    >Delete</b-button
                  >
                </b-col>
                <b-col class="text-right">
                  <b-button variant="secondary" @click="onCancelEdit">
                    Cancel
                  </b-button>
                  <b-button
                    class="ml-2"
                    variant="primary"
                    type="submit"
                    :disabled="invalid"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-card>
      </div>
    </validation-observer>
    <b-modal id="modal-delete" @ok="onDelete" title="Confirm Delete">
      Confirm deletion of {{ bm_edit.name_first }} {{ bm_edit.name_last }}
    </b-modal>
  </div>
</template>
<script>
import SignAndSend from "@/components/SignAndSend.vue"

export default {
  name: "BoardMembers",
  components: {
    SignAndSend,
  },
  data() {
    return {
      board_members: [],
      board_members_required: null,
      has_issues: false,
      missing_advanced_training: null,
      submit_data: {},
      edit_mode: false,
      bm_edit: {},
      pws_id: "",
      pws_name: "",
      table_fields: [
        { key: "edit", label: "", sortable: false },
        { key: "name", label: "Name", sortable: true },
        { key: "title", label: "Title", sortable: true },
        { key: "term_begin_date", label: "Term Begin Date", sortable: false },
        { key: "term_end_date", label: "Term End Date", sortable: true },
        { key: "training_date", label: "Training Date", sortable: true },
        {
          key: "advanced_training_date",
          label: "Advanced Training Date",
          sortable: true
        }
      ],
      state_options: [
        { text: "", value: null },
        "MS",
        "AK",
        "AL",
        "AR",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MA",
        "MD",
        "ME",
        "MI",
        "MN",
        "MO",
        "MT",
        "NC",
        "ND",
        "NE",
        "NH",
        "NJ",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VA",
        "VT",
        "WA",
        "WI",
        "WV",
        "WY"
      ]
    }
  },
  mounted() {
    this.pws_id = this.$store.getters.pws_id
    this.pws_name = this.$store.getters.pws_name
    this.resetBMEdit()
    this.getBoardMembersRequired()
    this.getBoardMembers().then(() => {
      this.submit_data.board_members = this.board_members
      this.submit_data.pws_id = this.pws_id
    })
  },
  methods: {
    showSignSend() {
      this.submit_data.pws_id = this.pws_id
      // remove index_id we inserted
      this.submit_data.board_members = this.board_members.map(bm => {
        const new_bm = { ...bm }
        delete new_bm.index_id
        // delete all fields added for validation
        delete new_bm.address_complete
        delete new_bm.missing_dates
        delete new_bm.missing_phone
        delete new_bm.record_issues
        return new_bm
      })
      //console.log('prepared submit_data', this.submit_data)
      //console.log('bm data', this.board_members)
      this.$bvModal.show('sign-send')
    },
    async onSubmit() {
      const isValid = await this.$refs.obs1.validate()
      console.log("validated", isValid)
      if (!isValid) {
        return
      }
      console.log("saving", this.bm_edit)
      if ("index_id" in this.bm_edit) {
        // find the record
        const idx = this.board_members.findIndex(
          (el) => el.index_id == this.bm_edit.index_id
        )
        if (idx > -1) {
          this.board_members[idx] = this.bm_edit
        }
        console.log("updated ", this.bm_edit)
        this.resetBMEdit()
        this.edit_mode = false
      } else {
        // New Record
        const new_id = this.board_members.length + 1
        const new_record = this.bm_edit
        new_record.index_id = new_id
        this.board_members.push(new_record)
        this.resetBMEdit()
        this.edit_mode = false
        this.checkBMState(new_record)
      }
      this.updateHasIssues()
    },
    resetBMEdit() {
      this.bm_edit = {
        name_last: "",
        name_first: "",
        title: "",
        street: "",
        city: "",
        zip: "",
        state: null,
        email: "",
        phone: "",
        term_begin_date: "",
        term_end_date: "",
        training_date: "",
        advanced_training_date: ""
      }
    },
    onDelete() {
      console.log("deleting", this.bm_edit.index_id)
      const idx = this.board_members.findIndex(
        (el) => el.index_id == this.bm_edit.index_id
      )
      if (idx > -1) {
        console.log("found index", idx)
        this.board_members.splice(idx, 1)
        this.resetBMEdit()
        this.edit_mode = false
      }
      this.updateHasIssues()
    },
    onEdit(dset) {
      console.log(dset)
      this.bm_edit = JSON.parse(JSON.stringify(dset))
      this.edit_mode = true
    },
    onNew() {
      this.edit_mode = true
    },
    onCancelEdit() {
      this.resetBMEdit()
      this.edit_mode = false
    },
    formatPhoneNumber(e) {
      if (e.target.value) {
        var x = e.target.value.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/)
        if (x) {
          e.target.value = x[1] + "-" + x[2] + "-" + x[3]
          e.target.dispatchEvent(new Event("input"))
        }
      }
    },
    async getBoardMembersRequired() {
      const path = "api/board-members-required"
      let response = await this.axios.get(path, {
        params: { pws_id: this.pws_id }
      })
      console.log(response)
      if (response.data) {
        this.board_members_required = response.data.board_members_required
      }
    },
    async getBoardMembers() {
      const path = "api/board-members-latest"
      let response = await this.axios.get(path, {
        params: { pws_id: this.pws_id }
      })
      console.log(response)
      if (response.data) {
        //this.board_members = response.data.board_members
        let i = 1
        for (const bm of response.data.board_members) {
          bm.index_id = i
          this.checkBMState(bm)
          i += 1
          this.board_members.push(bm)
        }
      }
      this.updateHasIssues()
    },
    getValidationState({ dirty, validated, valid = null }) {
      // don't show green checkbox - never set to valid
      if (valid) {
        valid = null
      }
      return dirty || validated ? valid : null
    },
    checkBMState(bm) {
      //console.log('checking', bm)
      if (bm.index_id === undefined) {
        console.error('missing index_id', bm)
        return
      }
      //const warning_data = {}
      //this.warnings[bm.index_id] = warning_data
      //warning_data.record_issues = false
      if (bm.street && bm.city && bm.zip && bm.state) {
        //warning_data.address_complete = true
        bm.address_complete = true
      } else {
        //warning_data.address_complete = false
        //warning_data.record_issues = true
        bm.address_complete = false
      }
      // if missing dates
      if (!bm.term_begin_date || !bm.term_end_date || !bm.training_date) {
        //warning_data.record_issues = true
        bm.missing_dates = true
      } else {
        bm.missing_dates = false
      }
      // if missing phone
      if (!bm.phone) {
        //warning_data.record_issues = true
        bm.missing_phone = true
      } else {
        bm.missing_phone = false
      }
      //console.log('warnings', warning_data)
      if (bm.address_complete && !bm.missing_dates && !bm.missing_phone) {
        bm.record_issues = false
      } else {
        bm.record_issues = true
      }
      this.updateHasIssues()
    },
    updateHasIssues() {
      //this.has_issues = Object.values(this.warnings).some((w) => w.record_issues)
      this.has_issues = this.board_members.some((bm) => bm.record_issues)
      // at least two records need advanced training date
      this.missing_advanced_training = this.board_members.filter(
        (bm) => bm.advanced_training_date
      ).length < 2
    }
  },
  watch: {
    // add warnings to bm_edit
    bm_edit: {
      deep: true,
      immediate: false,
      handler(newVal, oldVal) {
        // if we changed address_complete or record_issues, ignore
        if (oldVal.address_complete !== newVal.address_complete || oldVal.record_issues !== newVal.record_issues) {
          return
        }
        if (this.bm_edit && this.bm_edit.index_id) {
          this.checkBMState(this.bm_edit)
        }
      }
    }
  }
}
</script>
